
import { ReguaCobrancaService } from "@/core/services/regua";
import { ReguaCobranca } from "@/core/models/regua";
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import ListPage from '@/components/shared/ListPage';
import { Contrato } from "@/core/models/cadastros";
import { ContratoService } from "@/core/services/cadastros";

@Component
export default class ReguaPrincipal extends mixins(Vue, ListPage) {
  service = new ReguaCobrancaService();
  paginations = []

  currentPageCobrar = 1;
  perPage = 10;

  titulo: string = "Régua principal";
  subTitulo: string = "Lista das Réguas cadastradas no Sistema";

  filter = new Relatorio();

  options: any = {
    itemsPerPage: 15
  };

  totalLista = 0;

  headers: any[] = [
    { text: '', value: 'actions', sortable: false },
    { text: 'Nome', value: 'nome' },
  ];

  dialogCadastroRegua = false;

  Novo() {
    this.item = new ReguaCobranca();
    this.dialogCadastroRegua = true;
  }

  Editar(item) {
    this.service.ObterPorId(item.id, "Etapas").then(
      res => {
        this.item = res.data;
        this.dialogCadastroRegua = true;
      })
  }

  @Watch("options", { deep: true })
  Atualizar() {
    const { page, itemsPerPage, sortBy, sortDesc } = this.options;
    this.loading = true;

    this.service.Listar(page, itemsPerPage, sortBy, sortDesc, this.search, this.headers, undefined, 'Etapas').then(
      res => {
        this.lista = res.data.items;
        this.totalLista = res.data.count;
      },
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning");
        } else {
          this.$swal("Aviso", err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      }).finally(() => (this.loading = false));
  }

  etapas: any = [];
  loading: boolean = false;
  etapasx: any = [];

  GetReguaCobracaContrato() {
    this.loading = true;
    this.etapas = [];
    if (this.filter.id > 0) {
      this.service.GetContratos(this.filter.id).then(
        res => {
          this.etapasx = res.data.etapas.map((x) => ({
            page: 1,
            pageCount: 0,
            minimoDiasVencido: x.minimoDiasVencido,
            maximoDiasVencido: x.maximoDiasVencido,
            nome: x.nome,
            contratos: x.contratos
          }))
          this.loading = false;
          console.log(res.data)
        },
        err => {
          this.$swal("Aviso", err.response.data, "warning");
          this.loading = false;
        })
    }
  }

  DialogGerenciadorContrato: boolean = false;
  contrato = new Contrato();
  contratoService = new ContratoService();

  GetContrato(item) {
    this.contratoService.ObterPorId(item.id, "Receitas.Parcelas.Transacoes.UsuarioBoletoGerado, UnidadesAdicionais.Unidade, ContratosDigitais.Tipo, ContratosDigitais.Situacao, ContratosDigitais.Signatarios.Cliente, ContratosDigitais.Signatarios.Situacao, Receitas.Parcelas.AntecipacaoAmortizacao.Itens,TipoAmortizacao, Receitas.Parcelas.AntecipacaoComprovante, Observacoes, Receitas.Parcelas.Situacao, Receitas.Parcelas.Baixas.FormaPagamento,TipoIndice").then(
      res => {
        this.contrato = res.data;
        this.DialogGerenciadorContrato = true;
      },
      err => {
        this.$swal("Aviso", err.response.data, "warning");
      })
  }

  Excluir(item) {
    this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja excluir o registro atual?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.service.Excluir(item.id).then(
          (res) => {
            if (res.status == 200) {
              return res;
            }
          },
          (err) => this.$swal("Aviso", err.response.data, "error")
        );
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading(),
    }).then((result: any) => {
      if (result.value) {
        this.$swal(
          "Aviso",
          result.value.data,
          result.value.status == 200 ? "success" : "warning"
        );
        this.Atualizar();
      }
    });
  }

  mounted() {
    this.Atualizar();
  }
}
class Relatorio {
  public id: number = 0;
}
